var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize-ob",
          rawName: "v-resize-ob",
          value: _vm.windowResize,
          expression: "windowResize"
        }
      ],
      staticClass: "pageContainer",
      style: { margin: `${_vm.realTimeComputing(20)}px 0` }
    },
    [
      _c(
        "div",
        {
          staticClass: "containerHeader",
          style: { height: `${_vm.realTimeComputing(60)}px` }
        },
        [
          _c(
            "div",
            {
              staticClass: "goBack",
              style: { "margin-left": `${_vm.realTimeComputing(40)}px` },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            },
            [_c("i", { staticClass: "iconfont_Me icon-left iconStyle" })]
          )
        ]
      ),
      _c("div", { ref: "containerBody", staticClass: "containerBody" }, [
        _c("div", { staticClass: "backgroundImage" }, [
          _c("img", {
            attrs: {
              src: require("../../../../assets/images/email/loginDialog/background_image_en.png")
            }
          }),
          _c("div", {
            staticClass: "guidance",
            style: {
              width: `${_vm.realTimeComputing(295)}px`,
              height: `${_vm.realTimeComputing(34)}px`,
              left: `${_vm.realTimeComputing(114)}px`,
              bottom: `${_vm.realTimeComputing(177)}px`
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.goTopOfThePage.apply(null, arguments)
              }
            }
          })
        ]),
        _c(
          "div",
          {
            staticClass: "loginWindow",
            style: {
              top: `${_vm.realTimeComputing(67)}px`,
              right: `${_vm.realTimeComputing(145)}px`
            }
          },
          [
            _vm.emailRegisterCurrent == "login" ? _c("Login") : _vm._e(),
            _vm.emailRegisterCurrent == "register" ? _c("Register") : _vm._e(),
            _vm.emailRegisterCurrent == "selectSecurityType"
              ? _c("SecurityVerification")
              : _vm._e(),
            _vm.showSecureBox ? _c("SecureBox") : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }