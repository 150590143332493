<template>
  <div
    class="pageContainer"
    v-resize-ob="windowResize"
    :style="{ margin: `${realTimeComputing(20)}px 0` }"
  >
    <div
      class="containerHeader"
      :style="{ height: `${realTimeComputing(60)}px` }"
    >
      <div
        class="goBack"
        :style="{ 'margin-left': `${realTimeComputing(40)}px` }"
        @click.stop="goBack"
      >
        <i class="iconfont_Me icon-left iconStyle"></i>
      </div>
    </div>
    <div class="containerBody" ref="containerBody">
      <div class="backgroundImage">
        <img
          :src="
            require('../../../../assets/images/email/loginDialog/background_image_en.png')
          "
        />
        <div
          class="guidance"
          :style="{
            width: `${realTimeComputing(295)}px`,
            height: `${realTimeComputing(34)}px`,
            left: `${realTimeComputing(114)}px`,
            bottom: `${realTimeComputing(177)}px`,
          }"
          @click.stop="goTopOfThePage"
        ></div>
      </div>
      <div
        class="loginWindow"
        :style="{
          top: `${realTimeComputing(67)}px`,
          right: `${realTimeComputing(145)}px`,
        }"
      >
        <Login v-if="emailRegisterCurrent == 'login'" />
        <Register v-if="emailRegisterCurrent == 'register'" />
        <SecurityVerification
          v-if="emailRegisterCurrent == 'selectSecurityType'"
        />
        <SecureBox v-if="showSecureBox" />
      </div>
    </div>
  </div>
</template>
<script>
import Login from "../loginDialog/login.vue";
import Register from "../loginDialog/register";
import SecurityVerification from "../security/setupSecurityVerification.vue";
import SecureBox from "../security/secureBox.vue";
export default {
  name: "NonChineseLoginRegistrationPage",
  data() {
    return {
      widthListener: 0,
      originalWidth: 1460,
    };
  },
  components: {
    Login,
    Register,
    SecurityVerification,
    SecureBox,
  },
  computed: {
    emailRegisterCurrent() {
      return this.$store.state.melinked_email.emailRegisterCurrent;
    },
    showSecureBox() {
      let views = ["phone", "email", "QA"];
      return views.includes(this.emailRegisterCurrent);
    },
    current_popup_display_status() {
      return this.$store.state.melinked_email.current_popup_display_status;
    },
  },
  mounted() {
    const language = localStorage.getItem("langCode") || 1;
    if (language == 1) {
      this.$router.back();
      return;
    }
    this.$store.commit(
      "setEmailRegisterCurrentHistory",
      this.current_popup_display_status
    );
    if (this.current_popup_display_status != "login") {
      this.$store.commit("setCurrent_popup_display_status", "login");
    }
  },
  methods: {
    goTopOfThePage() {
      this.$emit("goTopOfThePage");
    },
    goBack() {
      this.$router.back();
    },
    realTimeComputing(actualValue) {
      const countWidth = Math.ceil(
        (actualValue / this.originalWidth) * this.widthListener
      );
      return countWidth > actualValue ? actualValue : countWidth;
    },
    windowResize: _.throttle(
      function(ob) {
        this.widthListener = ob.width;
      },
      160,
      {
        leading: false,
        trailing: true,
      }
    ),
  },
};
</script>
<style lang="stylus" scoped>
.pageContainer
  width: 100%;
  overflow: hidden;
  .containerHeader
    width: 100%;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #F0F0F0;
    .goBack
      width: 24px;
      height: 24px;
      .iconStyle
        font-size: 24px;
        color: #292929;
        cursor: pointer;
  .containerBody
    width: 100%;
    overflow: hidden;
    background: #FFFFFF;
    position: relative;
    .backgroundImage
      width: 100%;
      overflow: hidden;
      position: relative;
      img
        width: 100%;
        vertical-align: middle;
      .guidance
        position: absolute;
        z-index: 1;
        cursor: pointer;
    .loginWindow
      position: absolute;
      z-index: 1;
      border-radius: 10px;
      width: 27.08vw;
      height: 30.2vw;
      max-width: 520px;
      min-width: 360px;
      max-height: 580px;
      min-height: 403px;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
</style>
