<template>
  <div class="emailContainer" ref="emailContainer">
    <div class="componentBox">
      <NonChineseLoginRegistrationPage @goTopOfThePage="goTopOfThePage" />
    </div>
  </div>
</template>
<script>
import NonChineseLoginRegistrationPage from "../../components/melinked_email/for_en/index.vue";
export default {
  components: {
    NonChineseLoginRegistrationPage,
  },
  methods: {
    goTopOfThePage() {
      this.$refs.emailContainer.scrollTop = 0;
    },
  },
};
</script>
<style lang="stylus" scoped>
.emailContainer
  width: 100%;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  background: #F7F7F7;
  overflow-x: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar
    width: 0px;
  .componentBox
    width: 76vw;
    max-width: 1460px;
    min-width: 1160px;
    min-height: 0;
    overflow: hidden;
    margin: 0 auto;
</style>
