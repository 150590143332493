var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "emailContainer", staticClass: "emailContainer" }, [
    _c(
      "div",
      { staticClass: "componentBox" },
      [
        _c("NonChineseLoginRegistrationPage", {
          on: { goTopOfThePage: _vm.goTopOfThePage }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }